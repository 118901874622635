import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import GincurrentFeed from "../components/gincurrentfeed"
import GinpasteventsFeed from "../components/ginpasteventsfeed"

const GinHub = () => (

    <Layout>
        <SEO title="Gin Collection" />
  
        <PostHero
            title="Gin Collection"
            description="The juniper berry spirit"
            image={require('../images/juniper-berries.png')}
        />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">
                <GincurrentFeed />
            </div>

            <div className="hub-section-title center">
                <h2>Recent Gin Events</h2>
            </div>
            <div className="hub-cards-section">
                <GinpasteventsFeed />
            </div>

         </div>

      </div>

  </Layout>
)

export default GinHub


/*
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostHero from "../components/post-hero"
import HubCard from "../components/hubcard"

const GinHub = ({data}) => (

  <Layout>
    <SEO title="Gin Collection" />
  
    <PostHero
        title="Gin Collection"
        description="The juniper berry spirit"
        image={require('../images/juniper-berries.png')}
    />

      <div className="hub-wrapper">

        <div className="hub-section">

            <div className="hub-cards-section">

                {data.allContentfulCard.edges.map(edge => (
                    <HubCard
                        key={edge.node.id}
                        image={edge.node.image.fluid.src}                        
                        title={edge.node.title}    
                        contenttype={edge.node.contentType.title}
                        date={edge.node.date}
                        url={edge.node.url}
                        desc={edge.node.description.childMarkdownRemark.html}
                        alttext={edge.node.image.title}
                    />
                ))}

            </div>

         </div>

      </div>

  </Layout>
)

export default GinHub


export const query = graphql`
{
    allContentfulCard(sort: {fields: createdAt, order: DESC}, filter: {spiritType: {title: {eq: "Gin"}}}) {
        edges {
            node {
                title       
                url
                date
                contentType {
                    title
                }
                description {
                    childMarkdownRemark {
                        html
                    }
                }
                image {
                    title
                    fluid(maxWidth: 1200, quality: 85) {
                        src
                        ...GatsbyContentfulFluid
                    }
                }
                spiritType {
                    title
                }
                id
                createdAt        
            }
        }
    }
}
`
*/